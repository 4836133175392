<template>
  <div class="home">
    <el-form
      label-width="100px"
      inline
      label-position="left"
      :model="form"
      style="margin-top: 50px"
    >
      <el-form-item label="上传文件:" label-width="90px">
        <el-upload
          style="margin-right: 10px"
          class="upload-demo"
          ref="upload"
          action=""
          :auto-upload="false"
          :file-list="fileList3"
          :on-change="fileChange"
          multiple
          :show-file-list="false"
        >
          <el-button type="primary" size="small">{{
         '上传'
          }}</el-button>
        </el-upload>
      </el-form-item>
    </el-form>
    <input type="file" @change="handleFileChange">
  </div>
</template>

<script>
// import { ExcelExport } from "pikaz-excel-js";
import exportExcelMixin from "@/common/exportExcelMixin";
import AWS from "aws-sdk";
export default {
  name: "Home",
  mixins: [exportExcelMixin],
  // components: {
  //   ExcelExport,
  // },
  data() {
    return {
      form: {},

      fileList3: [],
      s3: new AWS.S3({
        // AWS 认证相关

        apiVersion: "2006-03-01",

        accessKeyId: "06574788FF6B41FBC51FFEAEA42B89CD",

        secretAccessKey: "FB35F2B1B068AD88BF3247A8BA431A8B",
        endpoint: "https://s3.cn-north-1.jdcloud-oss.com/",
        // region: "cn-north-1",
      }),

      videoUpload: null,

      videoLoading: false, // 防止再次点击

      videoProgress: 0, // 进度条

      videoFileName: "", // 文件名称

      videoSuffix: "", // 文件后
    };
  },
  watch: {
    emptyTableData(newValue) {
      if (newValue.length) {
        this.$message.error(`有${newValue.length}件商品没有库存,请及时处理`);
      }
    },
  },
  methods: {
    fileChange(file) {
      // console.log("file", file);
      this.file = file;
    },
    handleFileChange(e) {
      var that = this;

      let file = e.target.files[0];

      console.log("file change", file);

      if (file) {
        that.videoFileName = file.name;

        that.videoLoading = true;

        that.videoProgress = 0;

        // that.videoSuffix = that.videoFi.leName.split(".")[1];

        // var key =
        //   new Date().getTime() +
        //   "_" +
        //   Math.random().toFixed(2) +
        //   "." +
        //   that.videoSuffix;

        var params = {
          Bucket: "qingzhu", // 存储桶名称

          Key: 'static/app/' + file.name, // 文件名，重名会覆盖

          Body: file,
        };
        that.videoUpload = that.s3.upload(params, function (err, data) {
          if (err) {
            console.log("发生错误：", err.code, err.message);

            that.videoLoading = false;
          } else {
            console.log("上传成功, 返回结果");

            console.log(data);

            console.log(data.Location); //上传文件在S3的位置
          }
        });
      } else {
        console.log("1", 1);
      }
    },
  },
};
</script>

<style scoped>
.tableStyle {
  width: 80%;
  margin: 0 auto;
}
</style>
