import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import autRef from './common/updata.js'
Vue.config.productionTip = false
Vue.use(ElementUI);
let dateFormat = function(timestamp, formats) {
  // formats格式包括
  // 1. Y-m-d
  // 2. Y-m-d H:i:s
  // 3. Y年m月d日
  // 4. Y年m月d日 H时i分
  formats = formats || 'Y-m-d';

  var zero = function(value) {
    if (value < 10) {
      return '0' + value;
    }
    return value;
  };
  var formatTimestamp = function(value) {
    if (parseInt(value).toString().length == 10) {
      return parseInt(value) * 1000;
    }
    return parseInt(value)
  }
  if (!timestamp) {
    return "";
  }
  var myDate = timestamp ? new Date(formatTimestamp(timestamp)) : new Date();

  var year = myDate.getFullYear();
  var month = zero(myDate.getMonth() + 1);
  var day = zero(myDate.getDate());

  var hour = zero(myDate.getHours());
  var minite = zero(myDate.getMinutes());
  var second = zero(myDate.getSeconds());

  return formats.replace(/Y|m|d|H|i|s/ig, function(matches) {
    return ({
      Y: year,
      m: month,
      d: day,
      H: hour,
      i: minite,
      s: second
    })[matches];
  });
};
Vue.prototype.$dateFormat = dateFormat
// autRef()
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
