<template>
  <div class="home">
    <el-form
      label-width="100px"
      inline
      label-position="left"
      :model="form"
      style="margin-top: 100px"
    >
      <el-form-item label="商品明细:">
        <el-upload
          style="margin-right: 10px"
          class="upload-demo"
          ref="upload"
          accept="xls,xlsx"
          action=""
          :auto-upload="false"
          :file-list="fileList1"
          :on-change="
            (file, fileList) => {
              handleChange(file, fileList, 'one');
            }
          "
          multiple
          :show-file-list="false"
        >
          <el-button type="primary" size="small">{{
            config.one.length ? `已上传${config.one.length}条数据` : "请上传"
          }}</el-button>
        </el-upload>
      </el-form-item>

      <el-form-item label="输出表:" label-width="60px">
        <el-upload
          style="margin-right: 10px"
          class="upload-demo"
          ref="upload"
          accept="xls,xlsx"
          action=""
          :auto-upload="false"
          :file-list="fileList3"
          :on-change="
            (file, fileList) => {
              handleChange(file, fileList, 'three');
            }
          "
          multiple
          :show-file-list="false"
        >
          <el-button type="primary" size="small">{{
            config.three.length ? `已上传` : "请上传"
          }}</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="当前日期区间:">
        <el-date-picker
          v-model="mounths"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="exit">导出</el-button>
      </el-form-item>
    </el-form>

    <!-- <div style="color: red">日期不对注意检查columns</div> -->
    <div v-if="emptyTableData.length" class="tableStyle">
      <div style="margin-bottom: 10px; color: orange"><h2>缺失数据</h2></div>
      <el-table :data="emptyTableData" style="width: 100%">
        <el-table-column prop="名称" label="商品名称" width="">
        </el-table-column>
        <el-table-column prop="口味" label="口味"> </el-table-column>
        <el-table-column prop="SKU" label="SKU"> </el-table-column>
        <el-table-column prop="date" label="缺失日期">
          <template slot-scope="scope">
            <el-tag style="margin-right: 6px;" v-for="(item, index) in scope.row.date" :key="index">{{
              item
            }}</el-tag>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="京东分类" label="京东分类"> </el-table-column>
        <el-table-column prop="链接" label="链接"> </el-table-column>
        <el-table-column prop="商家SKU" label="商家SKU"> </el-table-column>
        <el-table-column prop="UPC编码" label="UPC编码"> </el-table-column>
        <el-table-column prop="SKU规格" label="SKU规格"> </el-table-column>
        <el-table-column prop="京东价" label="京东价"> </el-table-column>
        <el-table-column prop="商品状态" label="商品状态"> </el-table-column>
        <el-table-column prop="含税成本" label="含税成本"> </el-table-column> -->
      </el-table>
    </div>
  </div>
</template>

<script>
// import { ExcelExport } from "pikaz-excel-js";
import exportExcelMixin from "@/common/exportExcelMixin";
// import columns from "@/common/excelColumns";
let XLSX = require("xlsx");
export default {
  name: "Home",
  mixins: [exportExcelMixin],
  // components: {
  //   ExcelExport,
  // },
  data() {
    return {
      form: {},
      idOrigin: 1,
      textarea: "",
      fileList1: [],
      fileList2: [],
      fileList3: [],
      fileList4: [],
      emptyTableData: [],
      config: {
        three: [],
        two: [],
        one: [],
      },
      mounths: [],
      dataArray: [],
      dialogShow: false,
    };
  },
  watch: {
    emptyTableData(newValue) {
      if (newValue.length) {
        this.$message.error(`有${newValue.length}件商品缺失数据`);
      }
    },
  },
  methods: {
    reset() {
      this.config = {
        three: [],
        two: [],
        one: [],
      };
      this.mounths = [];
      this.dataArray = [];
    },
    getdate() {
      let start = new Date(this.mounths[0]);
      let day;
      let date = [];
      while (start <= this.mounths[1]) {
        day = start.getDate().toString();
        let month = start.getMonth() + 1;
        let startTime = month + "月" + day + "日";

        date.push(startTime);
        start.setDate(start.getDate() + 1);
      }
      return date;
    },
    handleChange(file, fileList, key) {
      // this.fileList = [fileList[fileList.length - 1]]; // 只能上传一个Excel，重复上传会覆盖之前的
      this.file = file.raw;
      // console.log("this.file", this.file);
      let reader = new FileReader();
      reader.readAsArrayBuffer(this.file);
      reader.onload = () => {
        let buffer = reader.result;
        let bytes = new Uint8Array(buffer);
        let length = bytes.byteLength;
        let binary = "";
        console.time();
        for (let i = 0; i < length; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        let wb = XLSX.read(binary, {
          type: "binary",
        });
        let outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
        if (key == "one") {
          this.config[key].push(outdata);
        } else {
          this.config[key] = outdata;
        }

        console.timeEnd();
        // console.log("this.config => ", JSON.parse(JSON.stringify(this.config)));
      };
    },
    beforeExit() {
      const outPutData = this.config.three;
      const tempConfig = {};
      let sku = "";
      outPutData.forEach((item) => {
        if (item.SKU) {
          sku = item.SKU;
          tempConfig[sku] = [item];
        } else {
          tempConfig[sku].push(item);
        }
      });
      for (let key in tempConfig) {
        let finalArr = [];
        this.config.one.forEach((oneItem, index) => {
          const detailData = oneItem.find((item) => {
            return item["商品ID"] == key;
          });
          if (detailData) {
            tempConfig[key].forEach((item) => {
              item.sku = key;
              if (item["数据口径"] == "UV") {
                item[this.dataArray[index]] = detailData["访客数"];
              }
              if (item["数据口径"] == "转化") {
                item[this.dataArray[index]] = this.format(
                  detailData["成交转化率"]
                );
              }
              if (item["数据口径"] == "GMV") {
                item[this.dataArray[index]] = detailData["成交金额"];
              }
            });
          } else {
            // console.log(
            //   "tempConfig[key][0] => ",
            //   JSON.parse(JSON.stringify(tempConfig[key][0]))
            // );
            this.emptyTableData.push(tempConfig[key][0]);
          }
        });
        // finalArr.push(final);
        tempConfig[key].push({ data: finalArr });
      }
      return tempConfig;
    },
    format(point) {
      const formated = Number(point * 100).toFixed(2);
      return `${formated}%`;
    },
    exit() {
      if (!this.mounths.length) {
        this.$message.warning("请选择导出日期区间");
        return;
      }
      if (!this.config.one.length) {
        this.$message.warning("请上传商品明细");
        return;
      }
      if (!this.config.three.length) {
        this.$message.warning("请上传输出表");
        return;
      }
      let data = [];
      this.dataArray = [];
      let columns = [
        { label: "商品名称", prop: "名称" },
        { label: "口味", prop: "口味" },
        { label: "商品编码", prop: "sku" },
        { label: "数据口径", prop: "数据口径" },
        // { label: "2月1日", prop: "2/1/23" },
        // { label: "2月2日", prop: "2/2/23" },
        // { label: "2月3日", prop: "2/3/23" },
        // { label: "2月4日", prop: "2/4/23" },
      ];

      this.getdate().forEach((item) => {
        const temp = item.replace(/月|日/g, "/") + "23";
        columns.push({
          label: item,
          prop: temp,
        });
        this.dataArray.push(temp);
      });
      // console.log("columns => ", JSON.parse(JSON.stringify(this.dataArray)));
      let tempArr = this.beforeExit();
      for (let key in tempArr) {
        data = [...data, ...tempArr[key]];
      }
      // console.log("data => ", JSON.parse(JSON.stringify(data)));

      //异常数组去重
      this.emptyTableData = this.emptyTableData.filter(
        (item, index) =>
          this.emptyTableData.findIndex((i) => i.SKU === item.SKU) === index
      );
      // 循环异常数据列表和输出data列表 找到异常数据对象，
      this.emptyTableData.forEach((item) => {
        item.date = [];
        const tempObj = data.find((Ditem) => {
          return Ditem.SKU == item.SKU;
        });
        if (tempObj) {
          //对比异常对象日期，赋值到table显示
          this.dataArray.forEach((dataItem) => {
            let flag = false;
            for (let key in tempObj) {
              if (key.includes(dataItem)) {
                flag = true;
                break;
              }
            }
            if (!flag) {              
              item.date.push(dataItem.substring(0, dataItem.length - 3));
            }
          });
        }
      });
      // // return
      // console.log(
      //   "this.emptyTableData => ",
      //   JSON.parse(JSON.stringify(this.emptyTableData))
      // );
      // return;
      this.exportExcel({
        columns: columns,
        data: data,
        title:
          "订单记录导出" +
          this.$dateFormat(new Date().getTime(), "Y_m_d_H_i_s"),
        // merges: ['A1', 'E1']
      }).then(() => {
        this.reset();
        this.$message({
          type: "success",
          message: "导出表格成功",
        });
      });
    },
  },
};
</script>

<style scoped>
.tableStyle {
  width: 80%;
  margin: 0 auto;
}
</style>
