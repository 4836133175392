import pluginExport from '@d2-projects/vue-table-export'
import Vue from "vue";

Vue.use(pluginExport)
export default {
  data() {
  },
  methods: {
    exportExcel(params) {
      return this.$export.excel(params);
    }
  },
}
